import { css } from '@emotion/react';
import { desktopFirstMediaQuery, Text, Title, useTheme } from '@inflearn/ds-react';
import { motion, type Variants } from 'framer-motion';
import React from 'react';
import { styleCommonSectionDescription, styleCommonSectionTitle } from '../SNS';
import { INTRODUCE_KEYFRAMES } from './constants';
import { styleSection } from './index';
import { commonSectionItemVariants, commonSectionVariants } from './variants';

const IntroduceSection = () => {
  const theme = useTheme();

  return (
    <motion.section
      css={[
        styleSection,
        {
          height: `${INTRODUCE_KEYFRAMES.LENGTH * 100}vh`
        }
      ]}>
      <motion.div css={[styleIntroduceSection]} initial="offscreen" whileInView="onscreen">
        <motion.div variants={commonSectionItemVariants({ delay: 0.1 })}>
          <Title
            order={2}
            color={theme.colors.dark[9]}
            css={[styleIntroduceTitle, styleOnlyDesktopTablet]}>
            {INTRODUCE_TEXT.DEFAULT.title}
          </Title>
          <Title
            order={2}
            color={theme.colors.dark[9]}
            css={[styleIntroduceTitle, styleOnlyMobile]}>
            {INTRODUCE_TEXT.MOBILE.title}
          </Title>
        </motion.div>
        <motion.div
          variants={commonSectionItemVariants({ delay: 0.2 })}
          css={styleOnlyDesktopTablet}>
          <Text
            color={theme.colors.gray[7]}
            mb={60}
            align="center"
            css={styleCommonSectionDescription}>
            {INTRODUCE_TEXT.DEFAULT.p1}
          </Text>
        </motion.div>
        <motion.div variants={commonSectionItemVariants({ delay: 0.2 })} css={styleOnlyMobile}>
          <Text
            color={theme.colors.gray[7]}
            mb={40}
            align="center"
            css={styleCommonSectionDescription}>
            {INTRODUCE_TEXT.MOBILE.p1}
          </Text>
        </motion.div>
        <motion.div
          variants={commonSectionVariants}
          css={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '320px'
          }}>
          <motion.div
            variants={backgroundItemVariants({ delay: 0.3 })}
            css={styleCommentBackground}>
            <motion.div
              variants={commonSectionItemVariants({ delay: 0.4 })}
              css={[
                styleCommentQuestion,
                {
                  position: 'absolute',
                  top: 32,
                  left: -64,
                  [desktopFirstMediaQuery.mobile]: {
                    top: 41,
                    left: -14
                  }
                }
              ]}>
              <Text component={'span'} color={theme.colors.dark[9]} size={24} weight={'bold'}>
                참가 신청 기간은요?
              </Text>
            </motion.div>
            <motion.div
              variants={commonSectionItemVariants({ delay: 0.5 })}
              css={[
                styleCommentAnswer,
                {
                  position: 'absolute',
                  top: 132,
                  right: -64,
                  [desktopFirstMediaQuery.mobile]: {
                    top: 150,
                    right: -14
                  }
                }
              ]}>
              <Text component={'span'} size={24} weight={'bold'}>
                7월 10일 월요일 13:00 부터
              </Text>
            </motion.div>
            <motion.div
              variants={commonSectionItemVariants({ delay: 0.6 })}
              css={[
                styleCommentAnswer,
                {
                  borderRadius: '40px',
                  position: 'absolute',
                  top: 210,
                  right: -64,
                  [desktopFirstMediaQuery.mobile]: {
                    top: 219,
                    right: -14
                  }
                }
              ]}>
              <Text component={'span'} size={24} weight={'bold'}>
                7월 13일 목요일 23:59 까지!
              </Text>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

const styleIntroduceSection = css({
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 32px',
  backgroundColor: '#f6fafe',
  [desktopFirstMediaQuery.mobile]: {
    padding: '40px 0 60px'
  }
});

type IntroduceTextType = {
  title: React.ReactNode;
  p1: React.ReactNode;
};

const INTRODUCE_TEXT: { DEFAULT: IntroduceTextType; MOBILE: IntroduceTextType } = {
  DEFAULT: {
    title: (
      <>
        인프런이 만드는 IT인의 축제
        <br />
        <Text component="span" color="blue.6">
          인프콘
        </Text>
        으로 초대합니다.
      </>
    ),
    p1: (
      <>
        기술 성장, 협업 & 생산성 향상,
        <br />
        핸즈온, IT 업계 커리어 등 다채로운 트랙과 경험을
        <br />
        공유할 수 있는 네트워킹 파티까지 즐겨보세요!
      </>
    )
  },
  MOBILE: {
    title: (
      <>
        인프런이 만드는 IT인의 축제
        <br />
        <Text component="span" color="blue.6">
          인프콘
        </Text>
        으로 초대합니다.
      </>
    ),
    p1: (
      <>
        기술 성장, 협업 & 생산성 향상,
        <br />
        핸즈온, IT 업계 커리어 등<br />
        다채로운 트랙과 경험을 공유할 수 있는
        <br />
        네트워킹 파티까지 즐겨보세요.
      </>
    )
  }
} as const;

export const styleOnlyDesktopTablet = css({
  display: 'inline-block',
  [desktopFirstMediaQuery.mobile]: {
    display: 'none'
  }
});

export const styleOnlyMobile = css({
  display: 'none',
  [desktopFirstMediaQuery.mobile]: {
    display: 'inline-block'
  }
});

const styleIntroduceTitle = css([
  styleCommonSectionTitle,
  {
    marginBottom: 40,
    [desktopFirstMediaQuery.mobile]: {
      marginBottom: 24
    }
  }
]);

const styleCommentBackground = css({
  position: 'absolute',
  width: 'min(300px, calc(100vw - 32px))',
  height: '320px',
  background: 'radial-gradient(220.9% 62.6% at 50% 108%, #f9fbff 0%, #ffffff 100%)',
  borderRadius: '36px'
});

const styleCommentQuestion = css({
  borderRadius: '4px 40px 40px 40px',
  padding: '16px 24px',
  backgroundColor: '#f7f7f7',

  [desktopFirstMediaQuery.mobile]: {
    span: {
      fontSize: '18px',
      lineHeight: 1.5
    }
  }
});

const styleCommentAnswer = css({
  borderRadius: '40px 4px 40px 40px',
  padding: '16px 24px',
  backgroundColor: '#228be6',
  span: {
    color: 'white'
  },

  [desktopFirstMediaQuery.mobile]: {
    span: {
      fontSize: '18px',
      lineHeight: 1.5
    }
  }
});

const backgroundItemVariants: ({ delay }: { delay: number }) => Variants = ({ delay }) => ({
  onscreen: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 30,
      delay
    }
  },
  offscreen: {
    opacity: 0,
    scale: 0.5
  }
});

export default IntroduceSection;
